<template>
  <ys-dialog :visible.sync="isVisible"
             @update:visible="visible=>$emit('update:visible', visible)"
             title="上传插件版本"
  >
    <div class="body padding padding-large">
      <div v-if="plugin" class="layout-horizontal layout-middle">
        <div class="font-size-large">
          <i class="fas fa-plug"></i>
        </div>
        <div class="margin-left">
          <div>
            <span class="font-weight-bold">{{plugin.tag}}</span>
            <span class="margin-left">{{plugin.name}}</span>
          </div>
          <div>
            <span v-if="plugin.last">最新版本：{{plugin.last.majorVersion}}.{{plugin.last.minorVersion}}</span>
          </div>
        </div>
      </div>
      <div class="divider margin-top"></div>
      <div class="layout-horizontal layout-middle margin-top-large">
        <div class="label layout-inflexible">
          <span class="mark-required"></span>
          <span>jar软件包：</span>
        </div>
        <ys-file-picker :file.sync="filePicked" class="layout-flexible" accept=".jar.original,.jar"></ys-file-picker>
      </div>
      <div class="layout-horizontal layout-middle margin-top-large">
        <div class="label layout-inflexible">
          <span class="mark-required"></span>
          <span>版本号：</span>
        </div>
        <ys-input class="layout-flexible"
                  placeholder="请输入版本号，例如：1.0。格式：主版本号.次版本号"
                  v-model="versionInputted"
                  :maxlength="20"
                  @input="handleVersionInputted"
        ></ys-input>
      </div>
      <div class="mark-version-error margin-top" v-if="versionInvalid">版本号格式错误，版本号格式应满足：主版本号.次版本号</div>
      <div class="layout-horizontal margin-top-large">
        <div class="label layout-inflexible">备注：</div>
        <ys-textarea
            class="layout-flexible"
            placeholder="输入版本备注信息（选填）"
            v-model="remarkInputted"
            :maxlength="200"
        ></ys-textarea>
      </div>
    </div>
    <div slot="footer">
      <ys-button type="secondary" lighting
        @click="handleCancelClick">取消</ys-button>
      <ys-button lighting class="margin-left"
                 :disabled="!filePicked || !majorVersion || !minorVersion"
                 @click="handleUploadClick"
      >上传</ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import httpapi from "@/assets/javascript/httpapi";
import YsFilePicker from "@/components/wedigets/YsFilePicker";
import YsInput from "@/components/wedigets/YsInput";
import YsButton from "@/components/wedigets/YsButton";
import ExactNumber from "@/assets/javascript/exact-number";
import YsTextarea from "@/components/wedigets/YsTextarea";

function doLoadPlugin() {
  if(this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  console.log('load plugin.....');
  this.$reqGet({
    path: '/plugin/module/get',
    data: {
      id: this.pluginId,
    }
  }).then(res=>{
    if(loadingCode !== this.loadingCode) return;
    this.plugin = res.data;
    let majorVersionValue = 1;
    let minorVersionValue = 0;
    if(this.plugin?.last) {
      majorVersionValue = this.plugin.last.majorVersion;
      minorVersionValue = this.plugin.last.minorVersion + 1;
    }
    if(!this.versionInputted) {
      this.majorVersion = ExactNumber.of(majorVersionValue, 0);
      this.minorVersion = ExactNumber.of(minorVersionValue, 0);
      this.versionInputted = `${majorVersionValue}.${minorVersionValue}`
    }
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doUploadPlugin() {
  if(this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/plugin/module/reference/upload',
    data: {
      id: this.pluginId,
      jarFile: this.filePicked,
      majorVersion: ExactNumber.toFloat(this.majorVersion),
      minorVersion: ExactNumber.toFloat(this.minorVersion),
      remark: this.remarkInputted,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.isVisible = false;
    this.$emit('update:visible', false);
    this.$emit('confirm', this.pluginId);
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "PluginUploadDialog",
  mixins: [httpapi],
  components: {YsTextarea, YsButton, YsInput, YsFilePicker, YsDialog},
  props: {
    visible: Boolean,
    pluginId: Number,
  },
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,

      isVisible: false,

      filePicked: null,

      majorVersion: null,
      minorVersion: null,

      versionInputted: null,
      versionInvalid: false,

      remarkInputted: null,

      plugin: null,
    }
  },
  watch: {
    visible: {
      handler() {
        this.isVisible = this.visible;
        if(this.visible) {
          this.loadingCode ++;
          this.plugin = null;
          this.filePicked = null;
          this.majorVersion = null;
          this.minorVersion = null;
          this.versionInputted = null;
          if(this.pluginId) {
            this.loadPlugin();
          }
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadPlugin() {
      doLoadPlugin.bind(this)();
    },
    handleVersionInputted() {
      if(!this.versionInputted) {
        this.versionInvalid = false;
        this.majorVersion = null;
        this.minorVersion = null;
      } else {
        this.versionInvalid = !(/^\d+\.\d+$/.test(this.versionInputted));
        if(!this.versionInvalid) {
          let tmps = this.versionInputted.split('\.');
          let majorVersionValue = parseInt(tmps[0]);
          let minorVersionValue = parseInt(tmps[1]);
          this.majorVersion = ExactNumber.of(majorVersionValue, 0);
          this.minorVersion = ExactNumber.of(minorVersionValue, 0);
        } else {
          this.majorVersion = null;
          this.minorVersion = null;
        }
      }
    },
    handleCancelClick() {
      this.isVisible = false;
      this.$emit('update:visible', false);
    },
    handleUploadClick() {
      if(!this.filePicked) {
        this.$message.warning('未选择文件');
        return;
      }
      if(!this.majorVersion || !this.minorVersion) {
        this.$message.warning('请输入版本号');
        return;
      }
      if(this.filePicked.__localFile) {
        this.$message.warning('文件正在上传，请稍后再试');
        return;
      }
      doUploadPlugin.bind(this)();
    }
  }
}
</script>

<style scoped>

.mark-required:after {
  content: "*";
  color: #ff5500;
}

.body {
  max-width: 500px;
}

.mark-version-error {
  background-color: #f0c78a;
  color: #ff5500;
  padding: 4px 8px;
  border-radius: 100px;
}

.label {
  min-width: 80px;
}

</style>